<template>
  <div>
    <b-card>
      <b-button-toolbar>
        <b-button-group>
          <b-button
            v-b-modal.tagsModal
            variant="primary"
          >
            Assign tags to accounts
          </b-button>
          <b-button
            v-b-modal.deleteMultipleModal
            variant="info"
          >
            Delete accounts
          </b-button>
          <b-button
            v-b-modal.createModal
            variant="secondary"
          >
            Create account
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card>
    <gql-table
      ref="accountsGqlTable"
      :fields="columns"
      :query="query"
      query-type="accounts"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="accountActionsList"
      :toggle-filters="true"
      :title="title"
      v-on="accountHandlers"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <row-detail :row="row" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account_type_name)="data">
        {{ accountTypes[data.item.account_type_name] }}
      </template>

      <template #cell(account_subtype_name)="data">
        {{ accountSubtypes[data.item.account_type_name][data.item.account_subtype_name] }}
      </template>

      <template #cell(rules)="data">
        {{ data.item.rule_max_daily_drawdown }}%|{{ data.item.rule_max_total_drawdown }}%|{{ data.item.rule_min_trading_days }}|{{ data.item.rule_first_withdrawal_after }}|{{ data.item.rule_withdrawal_after }}|{{ data.item.profit_split }}% | {{ data.item.execution_type }} - {{ ddTypes.find(rule => rule.value === data.item.rule_drawdown_type).label }}
      </template>

      <template #cell(specialOption)="data">
        <span
          v-for="(option, key) in data.item.special_options"
          :key="key"
        > {{ option }}</span>
      </template>

      <template #cell(tags)="data">
        <tags-list
          :tags="data.item.tags"
          :extras-tags="getAccountExtraTags(data.item)"
        />
      </template>

      <template #cell(account_id)="data">
        <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
          {{ data.item.account_id }} ({{ data.item.server.platform.id }})
        </b-link>
        <risk-score-badge
          v-if="data.item.risk_score"
          class="mx-1"
          :score-results="data.item.risk_score_results"
          :score="data.item.risk_score"
        />

        <b-link
          :to="{ name: 'tools-risk-score', params: { id: data.item.account_id } }"
          target="_blank"
        >
          <b-button
            v-b-tooltip.hover.top="'Open risk score analysis'"
            class="m-50"
            variant="outline-primary"
            size="sm"
          >
            RS
          </b-button>
        </b-link>
      </template>

      <template #cell(parent_id)="data">
        <b-link
          v-if="data.item.parent"
          :to="{ name: 'account-detail', params: { id: data.item.parent.account_id } }"
        >
          {{ data.item.parent.account_id }}
        </b-link>
      </template>

      <template #cell(order_id)="data">
        <b-link
          v-if="data.item.order"
          :to="{ name: 'order-detail', params: { id: data.item.order.order_number } }"
        >
          {{ data.item.order.order_number }}
        </b-link>
      </template>

      <template #cell(balance)="data">
        <b-badge
          :variant="data.item.balance>data.item.deposit ? 'success' : 'danger'"
        >
          {{ formatCurrency(data.item.balance, data.item.currency.label) }}
        </b-badge>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(start_datetime)="data">
        {{ formatDateTime(data.item.start_datetime) }}
      </template>

      <template #cell(end_datetime)="data">
        {{ formatDateTime(data.item.end_datetime) }}
      </template>

      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.item.close_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>
    </gql-table>
    <account-edit-modal />
    <account-reset-modal />
    <contract-data-modal />
    <account-copy-modal />
    <deposit-withdraw-modal />
    <download-funded-certificate-modal />
    <b-modal
      id="createModal"
      title="Create Account"
      :no-close-on-backdrop="true"
      size="xl"
      hide-footer
    >
      <new-account :user="user" />
    </b-modal>
    <b-modal
      id="tagsModal"
      title="Assign tags to accounts"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <multiple-tag-form
        items-name="Accounts ids"
        :is-busy="tagsToAccountsLoading"
        show-assign-to-users-btn
        @submit="onSubmitMultipleTags"
      />
    </b-modal>
    <b-modal
      id="deleteMultipleModal"
      title="Delete accounts by account number (separated by ',')"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <text-collection-form
        items-name="Accounts ids"
        :is-busy="accountsDeleteLoading"
        @submit="onSubmitDeleteMultiple"
      />
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BButton, BButtonGroup, BButtonToolbar, BCol, BLink, BModal, BRow,
  VBTooltip, BCard,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import {
  ACCOUNTS_SHORT, ACCOUNT_PHASES, DRAWDOWN_TYPES_ENUM, STATE_VARIANTS,
} from '@/enums/accounts'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { getUserExtraTags } from '@/plugins/userUtils'
import AccountAPI from '@/api/account'
import RowDetail from '@/components/page/accounts/components/RowDetail.vue'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import NewAccount from '@/views/account/new/NewAccount.vue'
import TextCollectionForm from '@/components/forms/TextCollectionForm.vue'
import MultipleTagForm from '@/components/forms/tag/MutipleTagForm.vue'
import accountActions from '@/mixins/accountActions'

const query = [
  'id',
  'account_id',
  'account_type_name',
  'account_subtype_name',
  'balance',
  'deposit',
  'createdAt',
  'start_datetime',
  'end_datetime',
  'close_datetime',
  'state',
  'password',
  'investor_password',
  'risk_score',
  'risk_score_results',
  'execution_type',
  'phase_move_check',
  'equity_check',
  'rule_drawdown_type',
  'rule_first_withdrawal_after',
  'rule_inheritance',
  'rule_max_daily_drawdown',
  'rule_max_days',
  'rule_max_total_drawdown',
  'rule_min_profit',
  'rule_min_trading_days',
  'rule_special',
  'rule_withdrawal_after',
  'profit_split',
  'extras_tags',
  'comment',
  'special_options',
  { order: { fields: ['id', 'order_number'] } },
  { parent: { fields: ['id', 'account_id'] } },
  { rootParent: { fields: ['id', 'account_id', { order: { fields: ['id', 'order_number'] } }] } },
  { currency: { fields: ['label'] } },
  { user: { fields: ['firstName', 'lastName', 'id', 'email', 'risk_score_results', 'risk_score', { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }] } },
  { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
  { server: { fields: ['id', 'name', { platform: { fields: ['id'] } }] } },
]

export default {
  components: {
    BButtonToolbar,
    BButtonGroup,
    MultipleTagForm,
    TextCollectionForm,
    NewAccount,
    ShowDetails,
    RowDetail,
    BButton,
    BModal,
    BBadge,
    TagsList,
    BRow,
    BCol,
    RiskScoreBadge,
    BLink,
    BCard,
    GqlTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [accountActions],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      query,
      tagsToAccountsLoading: false,
      accountsDeleteLoading: false,
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      ddTypes: DRAWDOWN_TYPES_ENUM,
      stateVariants: STATE_VARIANTS,
      downloadLoading: false,
      accountDownloadCertificate: null,
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getAccountExtraTags,
    getUserExtraTags,
    onSubmitMultipleTags(data) {
      this.tagsToAccountsLoading = true
      AccountAPI.postTagsToAccounts(data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tags was assigned to accounts',
              icon: 'SendIcon',
              text: 'Tags was successfully assigned to accounts.',
              variant: 'success',
            },
          })
          this.$bvModal.hide('tagsModal')
        })
        .catch(error => {
          if (error.status === 400) {
            Swal.fire({
              title: 'Some tags was not assign to accounts',
              text: error.data.detail,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            })
          }
        })
        .finally(() => {
          this.$refs.accountsGqlTable.reloadAll(true)
          this.tagsToAccountsLoading = false
        })
    },
    onSubmitDeleteMultiple(items) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Accounts will be permanently deleted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then(result => {
        if (result.isConfirmed) {
          this.accountsDeleteLoading = true
          AccountAPI.deleteAccounts(items)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'All accounts was removed.',
                  icon: 'SendIcon',
                  text: 'All accounts was removed.',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('deleteMultipleModal')
            })
            .catch(error => {
              if (error.status === 400) {
                Swal.fire({
                  title: 'This accounts can not be removed: ',
                  text: error.data.detail,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok',
                })
              }
            })
            .finally(() => {
              this.$refs.accountsGqlTable.reloadAll(true)
              this.accountsDeleteLoading = false
            })
        }
      })
    },
    reload() {
      this.$refs.accountsGqlTable.reloadAll(true)
    },
  },
}
</script>
