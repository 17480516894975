<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <div class="d-flex justify-content-start flex-wrap">
            <b-avatar
              v-if="!isMobile"
              variant="light-primary"
              size="104px"
              rounded
            >
              <b-img
                v-if="profileImage"
                :src="profileImage"
              />
              <UserIcon v-else />
            </b-avatar>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1 d-flex flex-wrap">
                <div>
                  <h4 class="mb-0">
                    {{ user.full_name }}
                  </h4>
                  <span class="card-text">{{ user.email }}</span>
                </div>
                <tags-list
                  :tags="user.tags"
                  class="m-50"
                  :show-hidden-by-toggle="false"
                />
              </div>
              <div class="mt-auto">
                <b-button
                  v-b-tooltip.hover.top="'Edit user'"
                  variant="outline-primary"
                  class="btn-icon m-50"
                  @click="onEdit(user)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Show verification data'"
                  v-b-modal.verifyModal
                  variant="outline-primary"
                  class="btn-icon m-50"
                >
                  <feather-icon icon="UserCheckIcon" />
                </b-button>
                <b-button
                  class="m-50"
                  variant="outline-warning"
                  @click="onRsSummary"
                >
                  RS Summary
                </b-button>
                <b-button
                  class="m-50"
                  variant="outline-info"
                  @click="onClickIpCheck(user)"
                >
                  IP checker
                </b-button>
                <b-dropdown
                  class="mx-1"
                  variant="outline-primary"
                  right
                  text="Options"
                >
                  <b-dropdown-item v-b-modal.downloadSocialCertificateModal>
                    Download social certificate
                  </b-dropdown-item>
                  <b-dropdown-item v-b-modal.downloadTotalPayoutCertificateModal>
                    Download total certificate
                  </b-dropdown-item>
                  <b-dropdown-item @click="onLoginAs(user)">
                    Login as User
                  </b-dropdown-item>
                  <b-dropdown-item @click="onResetMtrPassword(user)">
                    Reset MTR password
                  </b-dropdown-item>
                  <b-dropdown-item @click="getPl">
                    Show P/L
                  </b-dropdown-item>
                  <b-dropdown-item @click="getPassRates">
                    Show pass rates
                  </b-dropdown-item>
                  <b-dropdown-item @click="onResetVerify(user)">
                    Reset verification
                  </b-dropdown-item>
                  <b-dropdown-item @click="onVerify(user)">
                    Verify
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  v-if="isMobile"
                  variant="outline-info"
                  class="btn-icon"
                  @click="showUserInfo = !showUserInfo"
                >
                  <feather-icon :icon="showUserInfo ? 'EyeOffIcon' : 'EyeIcon'" />
                  Show user info
                </b-button>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap align-items-center mt-2">
            <div class="d-flex align-items-center mr-2 mt-50">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="CreditCardIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ formatCurrency(totalSpendSum, 'USD') }}
                </h5>
                <small>Total sum of user spend</small>
              </div>
            </div>

            <div class="d-flex align-items-center mr-2 mt-50">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ totalActiveAccounts }}
                </h5>
                <small>Total active accounts</small>
              </div>
            </div>

            <div class="d-flex align-items-center mr-2 mt-50">
              <b-avatar
                variant="light-danger"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ totalSoldAccounts }}
                </h5>
                <small>Total sold accounts</small>
              </div>
            </div>

            <div class="d-flex align-items-center mr-2 mt-50">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="BarChart2Icon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ totalTrialAccounts }}
                </h5>
                <small>Total trial accounts</small>
              </div>
            </div>

            <div class="d-flex align-items-center mr-2 mt-50">
              <b-avatar
                variant="light-danger"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ formatCurrency(totalWithdraw.withdraw, 'USD') }} | {{ totalWithdraw.payouts_count }}
                </h5>
                <small>Total withdraw</small>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="!isMobile || showUserInfo"
          cols="12"
          xl="6"
          class="p-1"
        >
          <b-row>
            <b-col>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </b-col>
            <b-col>
              {{ user.address }}, {{ user.zipcode }} {{ user.city }} {{ user.state }}
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Phone</span>
            </b-col>
            <b-col>
              {{ user.phone }}
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <feather-icon
                icon="GlobeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </b-col>
            <b-col>
              {{ user.country.name }}
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <feather-icon
                icon="KeyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registered with affiliate key</span>
            </b-col>
            <b-col>
              {{ user.affiliate ? `${user.affiliate.aff_key} (${user.affiliate.aff_custom_key}) | ${user.affiliate.user.full_name} (${user.affiliate.user.email})` : 'User wasn\'t register with affiliate link' }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <feather-icon
                icon="KeyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registered with affiliate key(NEW AFFILIATE)</span>
            </b-col>
            <b-col>
              {{ user.partner ? `${user.partner.aff_custom_key} | ${user.partner.user.full_name} (${user.partner.user.email})` : 'User wasn\'t register with affiliate link' }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <feather-icon
                icon="UserPlusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registered</span>
            </b-col>
            <b-col>
              {{ formatDateTime(user.createdAt) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <feather-icon
                icon="CloudIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registration IP</span>
            </b-col>
            <b-col>
              {{ user.ip }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Verified at</span>
            </b-col>
            <b-col>
              {{ formatDateTime(user.kyc_at) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <feather-icon
                icon="FileTextIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Comment</span>
            </b-col>
            <b-col>
              {{ user.comment }}
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          xl="6"
        >
          <!--        active deposit-->
          <div
            v-if="activeDeposit.length > 0"
            class="mt-2"
          >
            <h4>Deposit in active accounts</h4>
            <div class="d-flex align-items-center flex-wrap mt-2">
              <div
                v-for="(accountsDeposit, key) in activeDeposit"
                :key="key"
                class="d-flex align-items-center mr-2"
              >
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ formatCurrency(accountsDeposit.deposit, accountsDeposit.currency) }}
                  </h5>
                  <small>{{ accountsDeposit.account_type_name.toUpperCase() }} {{ ACCOUNT_SUBTYPE[accountsDeposit.account_subtype_name].title }}</small>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!--    User stats-->
    <b-card v-if="userStats.statsLoaded">
      <b-row>
        <!--        user PL-->
        <b-col
          v-if="userStats.pl !== null && userStats.pl.length > 0"
          lg="3"
          sm="12"
        >
          <b-overlay :show="loadingPl">
            <table
              v-if="!isMobile"
              class="table-b-table-default"
            >
              <tr>
                <th
                  v-for="(h, key) in Object.keys(userStats.pl[0])"
                  :key="key"
                  class="p-1"
                >
                  {{ h.toUpperCase().replaceAll('_',' ') }}
                </th>
              </tr>
              <tr
                v-for="(item, key) in userStats.pl"
                :key="key"
              >
                <td
                  v-for="(v, k) in Object.values(item)"
                  :key="k"
                >
                  {{ v }}
                </td>
              </tr>
            </table>
            <table
              v-if="plSummary"
              class="table-b-table-default"
            >
              <tr>
                <th
                  class="p-1"
                >
                  CURRENCY
                </th>
                <th
                  class="p-1"
                >
                  Total
                </th>
              </tr>
              <tr
                v-for="(v, key) in plSummary"
                :key="key"
              >
                <td>
                  {{ key }}
                </td>
                <td>
                  {{ formatNumber(v) }}
                </td>
              </tr>
            </table>
          </b-overlay>
        </b-col>
        <b-col
          lg="6"
          sm="12"
        >
          <div
            v-if="userStats.fr2 > 0"
            class="d-flex align-items-center mr-2 mt-50"
          >
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="PercentIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatFloat(userStats.fr2) }}%
              </h5>
              <small>Funding rate Two phase account</small>
            </div>
          </div>

          <div
            v-if="userStats.fr3 > 0"
            class="d-flex align-items-center mr-2 mt-50"
          >
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="PercentIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatFloat(userStats.fr3) }}%
              </h5>
              <small>Funding rate Three phase account</small>
            </div>
          </div>

          <b-overlay :show="loadingPassRates">
            <div
              v-for="(pr, key) in filteredPassRates"
              :key="key"
              class="d-flex align-items-center mr-2 mt-50"
            >
              <b-avatar
                variant="light-danger"
                rounded
              >
                <feather-icon
                  icon="PercentIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ formatFloat(pr) }}%
                </h5>
                <small>Pass rate {{ key.toUpperCase().split("_")[0] }} {{ key.toUpperCase().split("_")[1] }}</small>
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
    <!-- User Edit  -->
    <user-modal />
    <b-modal
      id="verifyModal"
      title="Verification details"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <veriff-detail
        v-for="(veriff, key) in user.verifications"
        :key="key"
        :item="veriff"
      >
        <hr>
      </veriff-detail>
    </b-modal>
    <b-modal
      id="rsSummaryModal"
      title="Risk score summary"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-linkage-summary-table :advanced-filter="rsSummaryFilter" />
    </b-modal>
    <b-modal
      id="downloadSocialCertificateModal"
      title="Download social certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        :show-color="false"
        :show-type="false"
        :show-force-generate="false"
        @download="onDownloadSocial"
      />
    </b-modal>
    <b-modal
      id="downloadTotalPayoutCertificateModal"
      title="Download total payout certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        :show-color="false"
        :show-type="false"
        @download="onDownloadTotalPayout"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BButton, BModal, BImg, VBTooltip, BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { UserIcon } from 'vue-feather-icons'
import { dispatch, get } from 'vuex-pathify'
import _groupBy from 'lodash/groupBy'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TagsList from '@/components/page/TagsList.vue'
import VeriffDetail from '@/components/page/JsonRecursiveList.vue'
import {
  formatDateTime, formatCurrency, formatFloat, formatNumber,
} from '@/plugins/formaters'
import { ACCOUNT_SUBTYPE } from '@/enums/accounts'
import UserAPI from '@/api/user'
import AccountLinkageSummaryTable from '@/components/entity/accountLinkageSummary/AccountLinkageSummaryTable.vue'
import CertificateDownloadForm from '@/components/forms/certificate/CertificateDownloadForm.vue'
import userActions from '@/mixins/userActions'

export default {
  components: {
    CertificateDownloadForm,
    AccountLinkageSummaryTable,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BModal,
    UserIcon,
    TagsList,
    BImg,
    VeriffDetail,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [userActions],
  data() {
    return {
      downloadLoading: false,
      ACCOUNT_SUBTYPE,
      userStats: {
        statsLoaded: false,
        pl: null,
        fr2: 0,
        fr3: 0,
        passRates: [],
      },
      loadingPassRates: false,
      loadingPl: false,
      rsSummaryFilter: { search: '', user_id: null, country: null },
      showUserInfo: false,
    }
  },
  computed: {
    ...get('usersList@row', {
      user: 'user_data',
      accounts: 'accounts',
      activeDeposit: 'active_deposit',
      totalWithdraw: 'total_withdraw',
      totalActiveAccounts: 'active_accounts_count',
      totalSoldAccounts: 'total_sold',
      totalSpendSum: 'total_spend',
      totalTrialAccounts: 'trials_count',
    }),
    ...get('app', ['isMobile']),
    filteredPassRates() {
      return Object.fromEntries(Object.entries(this.userStats.passRates)
        .filter(([, val]) => val > 0))
    },
    profileImage() {
      return this.user.profile_image ? `${process.env.VUE_APP_API_URL}users/${this.user.id}/avatar/${this.user.profile_image}` : null
    },
    plSummary() {
      if (this.userStats.pl === null || this.userStats.pl.length === 0) return []
      const byCurrency = _groupBy(this.userStats.pl, r => r.currency)
      Object.keys(byCurrency).forEach(c => {
        byCurrency[c] = byCurrency[c].map(item => Number(item.pl_sum)).reduce((prev, next) => prev + next)
      })

      return byCurrency
    },
  },
  methods: {
    formatNumber,
    formatDateTime,
    formatCurrency,
    formatFloat,
    reload() {
      dispatch('usersList/get', this.user.id)
    },
    onRsSummary() {
      this.rsSummaryFilter.user_id = this.user.id
      this.rsSummaryFilter.country = this.user.country.id
      this.$bvModal.show('rsSummaryModal')
    },
    getPassRates() {
      this.loadingPassRates = true
      UserAPI.getUserPassRates(this.user.id).then(response => {
        this.userStats.fr2 = response.fr2
        this.userStats.fr3 = response.fr3
        this.userStats.passRates = response.pass_rates
      }).finally(() => {
        this.loadingPassRates = false
        this.userStats.statsLoaded = true
      })
    },
    getPl() {
      this.loadingPl = true
      UserAPI.getUserPL(this.user.id).then(response => {
        this.userStats.pl = response
      }).finally(() => {
        this.loadingPl = false
        this.userStats.statsLoaded = true
      })
    },
    onDownloadSocial(form) {
      this.downloadLoading = true

      UserAPI.getCertificate(this.user.email, this.user.id, form.size, 'social')
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.downloadLoading = false
          this.$bvModal.hide('downloadSocialCertificateModal')
        })
    },
    onDownloadTotalPayout(form) {
      this.downloadLoading = true

      UserAPI.getCertificate(this.user.email, this.user.id, form.size, 'total', true)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.downloadLoading = false
          this.$bvModal.hide('downloadTotalPayoutCertificateModal')
        })
    },
  },
}
</script>
